import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import { useNavContext } from '../../Context/navContext';
import AnnouncementCard from "./AnnouncementCard";

function MainDuyuru() {
  const { navInfo } = useNavContext();
  const [records, setRecords] = useState([]);
  const [latestAnnouncements, setLatestAnnouncements] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 7;

  // Verileri çekme fonksiyonu
  const fetchAllRecords = async () => {
    try {
        const res = await axios.get("https://www.duyurular.org/api/duyuru/first");

        // Aktif olmayan (pasif) ve null/boş olan kayıtları filtrele
        const filteredRecords = res.data.filter(record => record.active !== 'pasif');

        // Duyuru_date'yi kontrol et ve parse et
        const parseDate = (dateString) => {
            if (!dateString) return null;
            // Tarih ayırıcıyı kontrol et
            const separator = dateString.includes('.') ? '.' : '/';
            const [day, month, year] = dateString.split(separator).map(Number);
            return new Date(year, month - 1, day); // Aylar 0'dan başlar
        };

        // Sıralama işlemi (tarihe göre, aynı tarih varsa id'ye göre)
        const sortedRecords = filteredRecords.sort((a, b) => {
            const duyuruDateA = parseDate(a.duyuru_date);
            const duyuruDateB = parseDate(b.duyuru_date);

            if (duyuruDateA && duyuruDateB) {
                // Tarihe göre sıralama (en yeni önce)
                if (duyuruDateB - duyuruDateA !== 0) {
                    return duyuruDateB - duyuruDateA;
                }
                // Aynı tarih için id'ye göre sıralama (id'si büyük olan önce)
                return b.id - a.id;
            }

            // Tarihlerden sadece biri varsa, var olanı üstte göster
            if (duyuruDateA) return -1;
            if (duyuruDateB) return 1;

            return 0; // İkisi de yoksa aynı sırada bırak
        });

        setRecords(sortedRecords);

        // En güncel 3 duyuruyu ayır
        setLatestAnnouncements(sortedRecords.slice(0, 3));

    } catch (error) {
        console.log(error);
    }
};


  useEffect(() => {
    fetchAllRecords();
  }, []); // Bileşen ilk yüklendiğinde veriyi çeker

  // navInfo değiştiğinde verileri yeniden çek
  useEffect(() => {
    setRecords([]); // Önce tüm kayıtları sıfırla
    fetchAllRecords(); // Ardından verileri yeniden çek
  }, [navInfo]); // navInfo değiştiğinde verileri yeniden çek
  

  // navInfo'ya göre records'u filtreleyip currentRecords'ı güncelle
  const filteredRecords = records.filter(record => 
    navInfo === 'kurumlar' ? record.tag === 'Kurumlar' : 
    navInfo === 'cumhurbaskanligi' ? record.tag === 'Cumhurbaskanliği' : 
    navInfo === 'bakanliklar' ? record.tag === 'Bakanliklar' : 
    navInfo === 'resmi-gazete' ? record.tag === 'Resmi-Gazete' : 
    true // Diğer durumlarda tüm kayıtları al
  );

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredRecords.slice(indexOfFirstRecord, indexOfLastRecord);

  const slowScrollToTop = () => {
    const scrollHeight = window.scrollY;
    const scrollStep = scrollHeight / 100;
    const delay = 7;

    const scrollAnimation = () => {
      if (window.scrollY > 0) {
        window.scrollTo(0, window.scrollY - scrollStep);
        setTimeout(scrollAnimation, delay);
      }
    };

    scrollAnimation();
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    slowScrollToTop();
  };

  return (
    <section className="section wb">
      <div className="container">
        <div className="row">
          <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
            <div className="page-wrapper">
              {currentRecords.map((record, index) => (
                <div key={index} className="blog-list clearfix">
                  <AnnouncementCard record={record} />
                </div>
              ))}
            </div>
            <hr className="invis" />
            <div className="row">
              <div className="col-md-12">
                <nav aria-label="Page navigation">
                  <ul className="pagination justify-content-start">
                    <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                      <Link className="page-link" onClick={() => handlePageChange(1)} to="#">
                        1
                      </Link>
                    </li>
                    <li className={`page-item ${currentPage === 2 ? "disabled" : ""}`}>
                      <Link className="page-link" onClick={() => handlePageChange(2)} to="#">
                        2
                      </Link>
                    </li>
                    <li className={`page-item ${currentPage === 3 ? "disabled" : ""}`}>
                      <Link className="page-link" onClick={() => handlePageChange(3)} to="#">
                        3
                      </Link>
                    </li>
                    {currentPage > 3 && (
                      <>
                        <li className="page-item">
                          <span className="page-link">...</span>
                        </li>
                        <li className={`page-item ${currentPage > 3 ? "active" : ""}`}>
                          <Link className="page-link" onClick={() => handlePageChange(currentPage)} to="#">
                            {currentPage}
                          </Link>
                        </li>
                      </>
                    )}
                    <li className={`page-item ${indexOfLastRecord >= filteredRecords.length ? "disabled" : ""}`}>
                      <Link className="page-link" onClick={() => handlePageChange(currentPage + 1)} to="#">
                        Sonraki
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <Sidebar latestAnnouncements={latestAnnouncements} />
        </div>
      </div>
    </section>
  );
}

export default MainDuyuru;

import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import axios from "axios";
import { Link } from "react-router-dom";

function Sidebar() {
  const [latestAnnouncements, setLatestAnnouncements] = useState([]);

  useEffect(() => {
    // API'den verileri çekmek için useEffect kullanıyoruz
    const fetchMostViewedAnnouncements = async () => {
      try {
        const response = await axios.get("https://www.duyurular.org/api/duyuru/mostviewed");
        setLatestAnnouncements(response.data);
      } catch (error) {
        console.error("Error fetching most viewed announcements:", error);
      }
    };

    fetchMostViewedAnnouncements();
  }, []);

  const handleSubscribe = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollStep = 8;
    const delay = 2;

    const scrollAnimation = () => {
      const currentPosition = window.scrollY;

      if (currentPosition + window.innerHeight < scrollHeight) {
        window.scrollTo(0, currentPosition + scrollStep);
        setTimeout(scrollAnimation, delay);
      }
    };

    scrollAnimation();
  };

  // Başlıkları 50 karakterle sınırlandır ve üç nokta ekle
  const truncateTitle = (title) => {
    const maxLength = 50;
    return title.length > maxLength ? title.slice(0, maxLength) + "..." : title;
  };

  const cleanTitle = (title) => {
    return title?.replace(/^[^\w\sİıĞğÜüŞşÇçÖö'"]+/g, "").trim() || ""; // ' ve " karakterlerini koruyacak
  };

  return (
    <>
      <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
        <div className="sidebar">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: 300,
              height: 200,
              mx: "auto",
              mt: 5,
              mb: 5,
              p: 3,
              bgcolor: "white",
              color: "black",
              borderRadius: 2,
              boxShadow: 3,
              textAlign: "center",
            }}
          >
            <Typography variant="body1" gutterBottom>
              Son duyuruların bildirimlerini almak ister misin?
            </Typography>
            <Typography variant="body2" gutterBottom>
              O zaman sitemize abone olun.
            </Typography>
            <Button
              variant="contained"
              onClick={handleSubscribe}
              sx={{
                mt: 2,
                bgcolor: "#2f41a5",
                "&:hover": {
                  bgcolor: "#5e8236",
                },
              }}
            >
              Abone Ol
            </Button>
          </Box>

          <div className="widget">
            <Typography variant="h6" fontWeight='bold' gutterBottom marginBottom={5}>
              En Çok Okunan Duyurular
            </Typography>
            <div className="blog-list-widget">
              <div className="list-group">
                {latestAnnouncements?.map((announcement, index) => (
                  <Link
                    key={index}
                    to={announcement.duyuru_content !== "" ? `/duyuru/${announcement.duyuru_link?.split('/').filter(Boolean).pop()}` : announcement.duyuru_link} state={{ id: announcement.id }} 
                    className="list-group-item list-group-item-action flex-column align-items-start"
                  >
                    <div className="w-100 justify-content-between">
                      <h5 className="mb-1">
                        {truncateTitle(cleanTitle(announcement.duyuru_title))}
                      </h5>
                      <small>
                        <i className="fa fa-eye" /> {announcement.viewers || 0} {/* İzlenme sayısını güncellenmiş viewers ile gösteriyoruz */}
                      </small>
                      <small style={{ fontWeight: 'bold', color: 'black' }}>
                        {announcement.name || "Yazar yok"} {/* Varsayılan yazar metni */}
                      </small>

                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;

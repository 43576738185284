import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

function AnnouncementCard({ record }) {
  const [viewers, setViewers] = useState(record?.viewers || 0); // Başlangıçta gelen viewers değerini state'e atıyoruz

  // record'un var olup olmadığını kontrol et
  useEffect(() => {
    if (record) {
      setViewers(record.viewers || 0);
    }
  }, [record]); // record değiştiğinde viewers değerini güncelle

  // Eğer record yoksa, null döndür
  if (!record) return null; // record null ise bileşeni render etme

  const slug = record.duyuru_link?.split('/').filter(Boolean).pop(); // Optional chaining kullanıldı

  // Başta harf olmayan karakterleri temizleyen ve Türkçe karakterleri koruyan fonksiyon
  const cleanTitle = (title) => {
    return title?.replace(/^[^\w\sİıĞğÜüŞşÇçÖö'"]+/g, "").trim() || ""; // ' ve " karakterlerini koruyacak
  };

  const truncateText = (text, maxLength) => {
    return (text?.length > maxLength ? text.slice(0, maxLength) + "..." : text) || ""; // Optional chaining ve varsayılan değer
  };

  const handleClick = async () => {
    let updatedViewers;

    // Eğer viewers değeri null veya undefined ise 1 olarak ayarla
    if (viewers === null || viewers === undefined) {
      updatedViewers = 1;
    } else {
      // Eğer viewers değeri varsa 1 arttır
      updatedViewers = parseInt(viewers, 10) + 1;
    }

    // API'ye güncellenmiş viewers bilgisini gönder ve response'u kontrol et
    try {
      const response = await axios.post('https://www.duyurular.org/api/duyuru/viewers', {
        id: record.id,
        viewers: updatedViewers,
      });

      // API'den gelen viewers verisini state'e atıyoruz
      if (response.data.viewers) {
        setViewers(response.data.viewers);
      }

    } catch (err) {
      console.error("Error updating viewers:", err);
    }
  };

  return (
    <>
      <hr className="invis" />
      <div className="blog-box row">
        <div className="col-md-3">
          <div className="post-media">
            <a href="garden-single.html" title="" onClick={handleClick}>
              <img style={{ width: "150px", height: "150px" }} src={record.image} alt="" className="img-fluid" />
            </a>
          </div>
        </div>
        <div className="blog-meta big-meta col-md-8">
          <h3>
            <Link to={record.duyuru_content !== '' ? `/duyuru/${slug}` : record.duyuru_link} state={{ id: record.id }} title="" onClick={handleClick}>
              {truncateText(cleanTitle(record.duyuru_title), 150)}
            </Link>
          </h3>
          <p dangerouslySetInnerHTML={{ __html: truncateText(record.duyuru_content, 150) }} />
          <div style={{ marginBottom: "10px" }}>
            <Link to={record.duyuru_content !== "" ? `/duyuru/${slug}` : record.duyuru_link} state={{ id: record.id }} onClick={handleClick}>Devamını okuyun...</Link>
          </div>
          <div style={{ marginTop: "10px" }}>
            <small>
              <a href="garden-category.html" title="">
                <i className="fa fa-eye" /> {viewers || 0} {/* İzlenme sayısını güncellenmiş viewers ile gösteriyoruz */}
              </a>
            </small>
            <small>
              <a href="garden-single.html" title="">
                {record.duyuru_date || "Tarih yok"} {/* Varsayılan tarih metni */}
              </a>
            </small>
            <small>
              <a href="garden-single.html" title="">
                {record.name || "Yazar yok"} {/* Varsayılan yazar metni */}
              </a>
            </small>
            <small>
              <a href="garden-single.html" title="">
                {record.duyuru_unit || ""} {/* Varsayılan birim metni */}
              </a>
            </small>
          </div>
        </div>
      </div>
    </>
  );
}

export default AnnouncementCard;

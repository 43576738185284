import React from "react";
import SubscriptionCard from "../Components/Body/SubscriptionCard";
import MainDuyuru from "../Components/Body/MainDuyuru";

function HomePage() {
  return (
    <div>
      {/* <SubscriptionCard/> */}
      <MainDuyuru/>
    </div>
  );
}

export default HomePage;

import React from 'react';

function Footer() {
  return (
    <footer className="footer">
    <div className="container">
      <div className="row">
        <div className="col-lg-8 offset-lg-2">
          <div className="widget">
            <div className="footer-text text-center">
              <a href="/">
                <img
                  src="images/version/garden-footer-logo.png"
                  alt=""
                  className="img-fluid"
                />
              </a>
              <p>
                Duyurular.org adresindeki en son yayınlanan tüm duyurulardan haberdar olmak için abone olmaya ne dersin mail adresini 
                ve duyuru almak istediğin alanları seç sana gönderelim!
              </p>
              <div className="social">
                <a
                  href="/"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title=""
                  data-original-title="Instagram"
                >
                  <i className="fa fa-instagram" />
                </a>
                <a
                  href="/"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title=""
                  data-original-title="Google Plus"
                >
                  <i className="fa fa-google-plus" />
                </a>
              </div>
              <hr className="invis" />
              <div className="newsletter-widget text-center">
              Çok yakında abonelik sistemi sizlerle ....
                {/* <SubscriptionCard/> */}
              </div>
              {/* end newsletter */}
            </div>
            {/* end footer-text */}
          </div>
          {/* end widget */}
        </div>
        {/* end col */}
      </div>
      <div className="row">
        <div className="col-md-12 text-center">
          <br />
          <br />
          <div className="copyright">
            © Duyurular.org : <a href="http://html.design">Hakkı Saklıdır 2024</a>.
          </div>
        </div>
      </div>
    </div>
    {/* end container */}
  </footer>
  
  
  )
}

export default Footer
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import Sidebar from './Sidebar';

function SingleAnnouncement() {
  const location = useLocation();
  const { id } = location.state || {}; 

  const [records, setRecords] = useState([]);
  const [relatedAnnouncements, setRelatedAnnouncements] = useState([]); 

  const cleanTitle = (title) => title?.replace(/^[^\w\sİıĞğÜüŞşÇçÖö'"]+/g, "").trim() || ""; 

  useEffect(() => {
    const fetchAllRecords = async () => {
      try {
        const res = await axios.get(`https://www.duyurular.org/api/duyuru/detay/${id}`);
        setRecords(res.data);
  
        axios
          .post("https://www.duyurular.org/api/duyuru/mostcommon", {
            tag: res.data.tag, // Burada doğrudan API yanıtını kullanıyoruz
            website_id: res.data.website_id,
            excludeId: res.data.id
          })
          .then((response) => setRelatedAnnouncements(response.data))
          .catch((error) => console.log(error));
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchAllRecords();
  }, [id]);

  const slowScrollToTop = () => {
    const scrollHeight = window.scrollY;
    const scrollStep = scrollHeight / 100;
    const delay = 7;

    const scrollAnimation = () => {
      if (window.scrollY > 0) {
        window.scrollTo(0, window.scrollY - scrollStep);
        setTimeout(scrollAnimation, delay);
      }
    };

    scrollAnimation();
  };
  return (
    <section className="section wb">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
            <div className="page-wrapper">
              <div className="blog-title-area">
                <span className="color-green">
                  <a href="/" title="">
                    {records.name}
                  </a>
                </span>
                <h3>{cleanTitle(records.duyuru_title)}</h3>
                <div className="blog-meta big-meta">
                  <small>
                    <a href="/" title="">
                      {new Date(records.duyuru_date).toLocaleDateString()}
                    </a>
                  </small>
                  <small>
                    <a href="/" title="">
                      {records.name}
                    </a>
                  </small>
                  <small>
                    <a href="/" title="">
                      <i className="fa fa-eye" /> {records.viewers}
                    </a>
                  </small>
                </div>
              </div>
              <div className="single-post-media">
                <img src={records.annimage} alt="" className="img-fluid" style={{ width: '700px', height: '450px' }} />
              </div>
              <div className="blog-content">
                <div className="pp">
                  <h3>
                    <strong>{records.duyuru_unit}</strong>
                  </h3>
                  <div dangerouslySetInnerHTML={{ __html: records.duyuru_content }} />
                  <Link to={records.duyuru_link || "#"}>
                    <span>Duyurunun asil sayfasına gitmek için tıklayınız</span>
                  </Link>
                </div>
              </div>
              <div className="blog-title-area">
                <div className="tag-cloud-single">
                  <span>Tags</span>
                  <small>{records.tag}</small>
                </div>
              </div>
              <hr className="invis1" />
              <div className="custombox clearfix">
                <h4 className="small-title">İlginizi çekebilecek duyurular</h4>
                <div className="row">
                  {relatedAnnouncements.map((announcement) => (
                    <div className="col-lg-6" key={announcement.id}>
                      <div className="blog-box">
                        <div className="post-media">
                          <Link to={announcement.duyuru_content !== '' ? `/duyuru/${announcement.duyuru_link?.split('/').filter(Boolean).pop()}` : announcement.duyuru_link} state={{ id: announcement.id }} onClick={()=>slowScrollToTop()} title="">
                            <img src={announcement.annimage} alt="" className="img-fluid" />
                            <div className="hovereffect">
                              <span className="" />
                            </div>
                          </Link>
                        </div>
                        <div className="blog-meta">
                          <h4>
                            <Link to={announcement.duyuru_content !== '' ? `/duyuru/${announcement.duyuru_link?.split('/').filter(Boolean).pop()}` : announcement.duyuru_link} state={{ id: announcement.id }} onClick={()=>slowScrollToTop()} title="">
                              {announcement.duyuru_title}
                            </Link>
                          </h4>
                          <small>
                            <a href="/" title="">
                              {announcement.tag}
                            </a>
                          </small>
                          <small>
                            <a href="/" title="">
                              {new Date(announcement.createdAt).toLocaleDateString()}
                            </a>
                          </small>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <Sidebar />
        </div>
      </div>
    </section>
  );
}

export default SingleAnnouncement;
